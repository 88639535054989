import { PropsWithChildren, ReactNode } from 'react'
import { useRouter } from 'next/navigation'
import { twMerge } from 'tailwind-merge'
import { MOBILE_EVENTS } from '@/consts'
import { isWebview, postMessageToApp } from '@/utils'
import { TEXT_ALIGN_STYLE } from '@/v1/Header/styles'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { V1_TABLET_WIDTH } from '../Layout/styles'
import LogoHomeButton from '../LogoHomeButton'

export const Header = ({
  className = '',
  children,
}: PropsWithChildren<{
  className?: string
}>) => {
  return (
    <>
      <header
        className={twMerge(
          'sticky',
          'top-0',
          'left-0',
          'mx-auto',
          'flex',
          'items-center',
          'justify-between',
          'bg-white',
          'h-[58px]',
          'z-20',
          V1_TABLET_WIDTH,
          className,
        )}
        suppressHydrationWarning
      >
        {children}
      </header>
    </>
  )
}

const Logo = () => {
  return <LogoHomeButton gaSelector="header_logo" />
}

const Title = ({
  text,
  align = 'center',
  className,
}: {
  text: ReactNode
  align?: 'start' | 'center'
  className?: string
}) => {
  return (
    <h3
      className={twMerge(
        'w-full',
        'prose-h5',
        TEXT_ALIGN_STYLE[align],
        className,
      )}
    >
      {text}
    </h3>
  )
}

interface IconButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  icon: ReactNode
  onClick?: () => void
  className?: string
  isMenu?: boolean
}

const IconButton = ({
  icon,
  onClick,
  className,
  ...restProps
}: IconButtonProps) => {
  const handleClick = () => {
    onClick?.()
  }

  return (
    <button
      type="button"
      className={twMerge(
        'flex items-center justify-center h-full px-4',
        className,
      )}
      onClick={handleClick}
      {...restProps}
    >
      {icon !== 'none' && icon}
    </button>
  )
}

interface BackIconButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
  onBack?: () => void
}

const BackIconButton = ({ onBack, ...restProps }: BackIconButtonProps) => {
  const isApp = isWebview()
  const router = useRouter()
  // onBack props가 존재한다면, 사용자가 지정한 뒤로 가기 동작이 실행됩니다.
  // 그렇지 않으면, Next.js의 기본 뒤로 가기 기능인 back() 함수를 실행하여 이전 페이지로 돌아갑니다.
  const handleBack = () => {
    if (onBack) {
      return onBack()
    }

    router.back()
    // 모바일 앱에 뒤로가기 이벤트를 전달한다.
    if (isApp) {
      postMessageToApp(MOBILE_EVENTS.GO_BACK)
    }
  }

  return (
    <IconButton
      icon={<MaterialSymbol name="arrow_back_ios_new" />}
      onClick={handleBack}
      {...restProps}
    />
  )
}

Header.Logo = Logo
Header.Title = Title
Header.IconButton = IconButton
Header.BackIconButton = BackIconButton
